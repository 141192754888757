import React, { useRef } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import CarouselSlider from './CarouselSlider';
import { Col, Image } from 'react-bootstrap';
import './CarouselSliderStudent.css';
import leftArrow from 'assets/letfArrow.png';
import rightArrow from 'assets/rightArrow.png';
import StudentPersona from './StudentPersona';
import Student1 from 'assets/Colombe.jpeg';
import Student2 from 'assets/Enguerrand_.jpeg';
import Student3 from 'assets/Lorie.jpeg';
import Student4 from 'assets/Mathis.png';

const CarouselSliderStudent = () => {
  const carouselRef = useRef(null);

  const persona = [
    {
      name: 'Lorie',
      description:
        'Les cours de maths avec Alexandre ont grandement amélioré mes compétences. Ambiance agréable et format efficace. Fortement recommandé !',
      imgUrl: Student3
    },
    {
      name: 'Enguerrand',
      description:
        'Site de grande qualité, avec des professeurs expérimentés, un travail en groupe, accessible à tous. MONA m’a aidé à progresser efficacement.',
      imgUrl: Student2
    },
    {
      name: 'Colombe',
      description:
        'En 2ᵉ année de prépa ECG, MONA m’aide énormément avec ses fiches et son soutien, essentiel pour réussir les concours',
      imgUrl: Student1
    },
    {
      name: 'Mathis',
      description:
        'En 2 mois avec Mona, j’ai beaucoup progressé. Les cours en duo avec Alexandre, expert du concours, sont efficaces et abordables.',
      imgUrl: Student4
    }
  ];

  const handleNext = () => {
    if (carouselRef.current) {
      carouselRef.current.next();
    }
  };

  const handlePrev = () => {
    if (carouselRef.current) {
      carouselRef.current.prev();
    }
  };

  return (
    <Col>
      <div className="d-flex justify-content-center align-items-center flex-column w-100 mb-5">
        <div className="d-flex w-100 justify-content-between">
          <div className="hec-carousel-slider-student-pin">Avis d’étudiants</div>
          <div className="hec-carousel-slider-student-controller">
            <Image
              src={leftArrow}
              onClick={handlePrev}
              width={13}
              height={26}
              style={{ cursor: 'pointer' }}
            />
            <Image
              src={rightArrow}
              onClick={handleNext}
              width={13}
              height={26}
              style={{ cursor: 'pointer' }}
            />
          </div>
        </div>
        <div className="d-flex justify-content-center flex-column align-items-start w-100">
          <h3 className="hec-carousel-slider-student-title">Ce qu’en pensent d’autres étudiants</h3>
          <h5 className="hec-carousel-slider-student-subtitle">
            Préparez-vous aux concours des grandes écoles de commerce avec notre formation
            d'excellence à prix abordables, où que vous soyez !
          </h5>
        </div>
      </div>
      <CarouselSlider
        ref={carouselRef}
        persona={persona}
        maxItemsToShow={2}
        renderItem={(persona) => (
          <StudentPersona
            name={persona.name}
            description={persona.description}
            imgUrl={persona.imgUrl}
          />
        )}
      />
    </Col>
  );
};

export default CarouselSliderStudent;
