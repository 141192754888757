import React, { useRef } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import CarouselSlider from './CarouselSlider';
import TeacherPersona from './TeacherPersona';
import { Col, Image } from 'react-bootstrap';
import './CarouselSliderTeacher.css';
import leftArrow from 'assets/letfArrow.png';
import rightArrow from 'assets/rightArrow.png';
import Hero1 from 'assets/Borhane.jpeg';
import Hero2 from 'assets/Clemence.jpeg';
import Hero3 from 'assets/ermance.jpeg';
import Hero4 from 'assets/Garance.jpeg';
import Hero5 from 'assets/Quentin.jpeg';
import Hero6 from 'assets/Théophile.jpeg';
import Hero7 from 'assets/Anna.jpeg';
import Hero8 from 'assets/Léni.jpeg';

const CarouselSliderTeacher = () => {
  const carouselRef = useRef(null);

  const persona = [
    {
      name: 'Borhane',
      subject: 'Prof de mathématiques',
      description:
        "Expert en Maths Appro et en ESH, ce double diplômé d'HEC-ENSAE saura vous guider pour exceller aux concours",
      imgUrl: Hero1,
      note: '4.3'
    },
    {
      name: 'Clémence',
      subject: 'Prof d’anglais',
      description:
        "Vous n'aurez plus aucun problème en Anglais, Allemand ou ESH grâce à cette étudiante d'HEC",
      imgUrl: Hero2,
      note: '5.0'
    },
    {
      name: 'Quentin',
      subject: 'Prof de géopolitique',
      description:
        'Géopo, Maths Appro, ce double diplômé HEC/ENSAE vous guidera pour aller chercher le double 20 aux parisiennes',
      imgUrl: Hero5,
      note: '4.8'
    },
    {
      name: 'Garance',
      subject: 'Prof de synthèse de texte',
      description:
        "cette experte en CG de l'ESCP éveillera votre conscience artistique et philosophique pour parfaire vos disserts",
      imgUrl: Hero4,
      note: '4.8'
    },
    {
      name: 'Léni',
      subject: "Prof d'HGGSP'",
      description:
        "Ce diplômé de l'ESSEC saura vous aider en géopo avec les meilleurs conseils, les meilleures fiches... pour obtenir les meilleures notes à chaque épreuve",
      imgUrl: Hero8,
      note: '4.3'
    },
    {
      name: 'Ermance',
      subject: 'Prof d’espagnol',
      description:
        "Double diplômée d'HEC-ENSAE, Ermance a eu 18 aux concours en espagnol à l'oral et à l'écrit ainsi que double 20 en Maths",
      imgUrl: Hero3,
      note: '5.0'
    },
    {
      name: 'Théophile',
      subject: 'Prof de mathématiques',
      description: "Major de promo à Hoche en géopo avec 15 à l'oral à HEC et double 20 en Maths",
      imgUrl: Hero6,
      note: '4.8'
    },
    {
      name: 'Anna',
      subject: 'Prof de mathématiques',
      description:
        "Cette élève de l'ESSEC saura vous aider en Allemand avec 17 à l'écrit et et en Maths Appro aec un double 19",
      imgUrl: Hero7,
      note: '4.8'
    }
  ];

  const handleNext = () => {
    if (carouselRef.current) {
      carouselRef.current.next();
    }
  };

  const handlePrev = () => {
    if (carouselRef.current) {
      carouselRef.current.prev();
    }
  };

  return (
    <Col>
      <div className="d-flex justify-content-center align-items-center flex-column w-100 mb-5">
        <div className="d-flex w-100 justify-content-between">
          <div className="hec-carousel-slider-teacher-pin">Les professeurs</div>
          <div className="hec-carousel-slider-teacher-controller">
            <Image
              src={leftArrow}
              onClick={handlePrev}
              width={13}
              height={26}
              style={{ cursor: 'pointer' }}
            />
            <Image
              src={rightArrow}
              onClick={handleNext}
              width={13}
              height={26}
              style={{ cursor: 'pointer' }}
            />
          </div>
        </div>
        <div className="d-flex justify-content-center flex-column align-items-start w-100">
          <h3 className="hec-carousel-slider-teacher-title">
            Des professeurs de qualité, sélectionnés avec soin
          </h3>
          <h5 className="hec-carousel-slider-teacher-subtitle">
            Préparez-vous aux concours des grandes écoles de commerce avec notre formation
            d'excellence à prix abordables, où que vous soyez !
          </h5>
        </div>
      </div>
      <CarouselSlider
        ref={carouselRef}
        persona={persona}
        maxItemsToShow={4}
        renderItem={(persona) => (
          <TeacherPersona
            name={persona.name}
            description={persona.description}
            subject={persona.subject}
            imgUrl={persona.imgUrl}
            chipset={persona.chipset}
          />
        )}
      />
    </Col>
  );
};

export default CarouselSliderTeacher;
