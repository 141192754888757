/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import { Image } from 'react-bootstrap';
import imageUrl from 'assets/hero1.png';
import star from 'assets/Ystar.svg';
import './StudentPersona.css';

const StudentPersona = ({ name, imgUrl, description }) => {
  return (
    <div className="d-flex justify-content-center align-items-center w-100 h-100">
      <Image src={imgUrl} alt="image prof" rounded fluid className="hec-persona-student-img" />
      <div className="hec-persona-student-card-container">
        <div className="ms-4">
          <Image src={star} className="hec-persona-student-star" />
          <Image src={star} className="hec-persona-student-star" />
          <Image src={star} className="hec-persona-student-star" />
          <Image src={star} className="hec-persona-student-star" />
          <Image src={star} className="hec-persona-student-star" />
        </div>
        <div className="hec-persona-student-description ms-4">"{description}"</div>
        <div className="ms-4">
          <span className="hec-persona-student-name"> {name} </span>
          <span className="hec-text-student-admission"> Admis à HEC </span>
        </div>
      </div>
    </div>
  );
};

StudentPersona.propTypes = {
  name: PropTypes.string.isRequired,
  imgUrl: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired
};

export default StudentPersona;
