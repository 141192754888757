import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './FastPhoneInput.css';
import { InputGroup, FormControl, Button, Modal } from 'react-bootstrap';
import { fetchWithAuthorization } from 'utils/fetchWithAutorization';
import { API_URL } from 'Env';

const FastPhoneInput = ({
  PhoneNumberPlaceholder = 'Entrez votre numéro de téléphone',
  ButtonText = 'En savoir plus',
  ButtonColor = '#007bff',
  ButtonTextColor = '#ffffff'
}) => {
  const [PhoneNumber, setPhoneNumber] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);

  const handlePhoneNumberChange = (e) => {
    setPhoneNumber(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!PhoneNumber.match(/^\+?[0-9]\d{1,14}$/)) {
      setModalMessage('Numéro de téléphone invalide. Veuillez réessayer.');
      setIsSuccess(false);
      setShowModal(true);
      return;
    }

    const requestOptions = {
      method: 'POST',
      body: JSON.stringify({ phone: PhoneNumber })
    };

    try {
      await fetchWithAuthorization(API_URL + '/api/email/send-phone', requestOptions);
      setModalMessage('Votre numéro a été envoyé avec succès !');
      setIsSuccess(true);
    } catch (error) {
      setModalMessage("Une erreur est survenue lors de l'envoi. Veuillez réessayer.");
      setIsSuccess(false);
    }

    setShowModal(true);
  };

  const handleCloseModal = () => setShowModal(false);

  return (
    <>
      <InputGroup>
        <FormControl
          id="fast-phone-input-formcontrol"
          type="tel"
          placeholder={PhoneNumberPlaceholder}
          pattern="[0-9]*"
          value={PhoneNumber}
          onChange={handlePhoneNumberChange}
          required
        />
        <Button
          id="fast-phone-input-btn"
          onClick={handleSubmit}
          style={{
            backgroundColor: ButtonColor,
            borderColor: ButtonColor,
            color: ButtonTextColor
          }}
        >
          {ButtonText}
        </Button>
      </InputGroup>

      {/* Modal pour afficher le message */}
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>{isSuccess ? 'Succès' : 'Erreur'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{modalMessage}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Fermer
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

FastPhoneInput.propTypes = {
  PhoneNumberPlaceholder: PropTypes.string,
  ButtonText: PropTypes.string,
  ButtonColor: PropTypes.string,
  ButtonTextColor: PropTypes.string
};

export default FastPhoneInput;
